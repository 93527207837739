import { toastError } from './toast';
import HttpClient from '../api/httpClient';
import {
  saveUserToken,
  deleteUserToken,
  getCachedToken,
  decodeToken,
} from '../utils/tokenUtils';

import {
  ENTREPENORKONTROLL_SERVICE_ID,
  getBymeldingClientUrl,
} from '../utils/environment';
import User from '../User/User';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const AUTH_TOKEN_IS_VALID = 'AUTH_TOKEN_IS_VALID';
export const AUTH_TOKEN_IS_INVALID = 'AUTH_TOKEN_IS_INVALID';
export const AUTH_TOKEN_IS_EXPIRED = 'AUTH_TOKEN_IS_EXPIRED';

export const LOGOUT_USER = 'LOGOUT_USER';

export default function loginReducer(state = null, action) {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...action.payload,
      };
    case LOGOUT_USER:
      return null;
    case AUTH_TOKEN_IS_VALID:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

const loginUserRequest = (epost) => ({
  type: LOGIN_USER_REQUEST,
  payload: epost,
});

const loginUserFailure = (error) => ({
  type: LOGIN_USER_FAILURE,
  payload: error,
  error: true,
});

const loginUserSuccess = (userData) => ({
  type: LOGIN_USER_SUCCESS,
  payload: userData,
});

export function loginUser({ epost, passord, serviceId }) {
  return async (dispatch) => {
    dispatch(loginUserRequest(epost));
    try {
      const response = await HttpClient.loginUser(
        epost,
        passord,
        serviceId || ENTREPENORKONTROLL_SERVICE_ID
      );
      const data = response.data.result;
      const decodedToken = decodeToken(data.access_token);
      saveUserToken(data);
      HttpClient.setDefaultCredentials(data.access_token);
      const user = User.fromToken(decodedToken);
      dispatch(loginUserSuccess(user));
      return {
        token: data.access_token,
        expireDate: new Date(decodedToken.exp * 1000),
      };
    } catch (error) {
      dispatch(loginUserFailure(error));
      dispatch(toastError(error));
      return Promise.reject(error);
    }
  };
}

export function logoutUser() {
  HttpClient.logOutUser();
  deleteUserToken();
  window.location.href = `${getBymeldingClientUrl()}/logout`;
  return { type: LOGOUT_USER };
}

const invalidToken = () => ({ type: AUTH_TOKEN_IS_INVALID });
const expiredToken = () => ({ type: AUTH_TOKEN_IS_EXPIRED });

export const loginUserFromStore = () => async (dispatch) => {
  try {
    const token = getCachedToken();
    if (token === null) {
      dispatch(invalidToken());
    }
    const decodedToken = decodeToken(token);
    const now = Date.now();
    const notBeforeTime = new Date(decodedToken.nbf * 1000);
    const expirationTime = new Date(decodedToken.exp * 1000);
    const expired = notBeforeTime > now || now > expirationTime;
    if (expired) {
      dispatch(expiredToken());
      return null;
    }
    await HttpClient.setDefaultCredentials(token);
    const user = User.fromToken(decodedToken);
    dispatch(loginUserSuccess(user));
    return Promise.resolve(true);
  } catch {
    return Promise.reject();
  }
};

export const loginUserFromRecievedToken = (token) => async (dispatch) => {
  try {
    if (token === null) {
      dispatch(invalidToken());
    }
    const JSONToken = JSON.parse(token);
    const decodedToken = decodeToken(JSONToken.access_token);
    const now = Date.now();
    const notBeforeTime = new Date(decodedToken.nbf * 1000);
    const expirationTime = new Date(decodedToken.exp * 1000);
    const expired = notBeforeTime > now || now > expirationTime;
    if (expired) {
      dispatch(expiredToken());
      return Promise.reject();
    }
    saveUserToken(JSONToken);
    await HttpClient.setDefaultCredentials(JSONToken);
    const user = User.fromToken(decodedToken);
    dispatch(loginUserSuccess(user));
    return Promise.resolve(true);
  } catch {
    return Promise.reject();
  }
};
