import React from 'react';
import { connect } from 'react-redux';

export const UserContext = React.createContext();

const UserProvider = (props) => {
  const { children, user } = props;

  console.log('Current user:', user);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  user: state.login,
});

export default connect(mapStateToProps, null)(UserProvider);
