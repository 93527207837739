import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { FaPrint, FaPen, FaTimes, FaTrashAlt, FaSpinner } from 'react-icons/fa';

import {
  Button,
  Badge,
  Input,
  Label,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { withRouter } from 'react-router';
import { map } from 'lodash';
import httpClient from '../../api/httpClient';
import Translate from '../../Locale/Translate';
import { getFormattedDate, getFormattedTime } from '../../utils/dateUtils';
import { LocaleContext } from '../../Locale/LocaleProvider';
import { UserContext } from '../../User/UserProvider';
import {
  getAvvikIcon,
  AVVIKTYPE_ENTREPRENOR,
  getFormattedAddress,
} from '../../utils/avvikUtils';
import BymMap from '../Common/Map/BymMap';
import { avvikStatuskoder } from '../../constants/status';
import { isNullOrWhitespace } from '../../utils/stringUtils';
import ConfirmModal from '../Common/ConfirmModal';
import BildeModal from '../Common/BildeModal';
import CommentsInput from '../CommentsInput/CommentsInput';
import Statushistorikk from './Statushistorikk';
import AvvikBladeConfirmModal from './AvvikBladeConfirmModal.tsx';
import StatusBadge from '../Common/StatusBadge.tsx';
import { sortByStringDesc } from '../../utils/sortUtils';

const AvvikBlade = (props) => {
  const {
    item,
    onClose,
    onSetStatus,
    onUpdateEndringslogg,
    history,
    statiskeData,
    loading,
  } = props;
  const { user } = useContext(UserContext);
  const localeContext = useContext(LocaleContext);

  const [valid, setValid] = useState(true);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const [validForSletting, setValidForSletting] = useState(true);
  const [nyStatus, setNyStatus] = useState({
    id: null,
    kommentar: null,
  });
  const [slettAvvikKommentar, setSlettAvvikKommentar] = useState();
  const [bekreftSlettSynlig, setBekreftSlettSynlig] = useState(false);
  const [bekreftStatusEndringSynlig, setBekreftStatusEndringSynlig] =
    useState(false);
  const [statushistorikkIsExpanded, setStatushistorikkIsExpanded] =
    useState(false);

  const getFormattedTextForVeitype = (id) => {
    if (
      statiskeData.feiltypeHierarki &&
      statiskeData.feiltypeHierarki.veityper
    ) {
      const collectionItem = statiskeData.feiltypeHierarki.veityper.find(
        (x) => x.id === id
      );
      return (collectionItem && collectionItem.navn) || '-';
    }

    return '';
  };

  const getFormattedEntrerprenor = (entreprenorselskapId) => {
    if (statiskeData.entreprenorselskaper) {
      const selskap = statiskeData.entreprenorselskaper.find(
        (s) => s.id === entreprenorselskapId
      );
      return (selskap && selskap.navn) || '-';
    }
    return '';
  };

  const getFormattedKontrakt = (kontraktId) => {
    if (statiskeData.kontrakter) {
      const kontrakt = statiskeData.kontrakter.find((s) => s.id === kontraktId);
      return (kontrakt && kontrakt.navn) || '-';
    }
    return '';
  };

  const getGoogleUrl = (adresse) => {
    return `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(
      adresse
    )}`;
  };

  const isEksternInnmelder = item.avviktypeId === AVVIKTYPE_ENTREPRENOR;
  const isKladd =
    item.status ===
    avvikStatuskoder.find((status) => status.key === 'Kladd').id;
  const isSlettet =
    item.status ===
    avvikStatuskoder.find((status) => status.key === 'Slettet').id;

  const getAvvikStatustekst = (id) => {
    const key = `kontrollpanel.avvik-status.${id}`;
    return localeContext.currentLocale.messages[key];
  };

  const kanEndreStatus = !isKladd && !isSlettet;

  const statusDropdownlistItems = avvikStatuskoder
    .filter((status) =>
      item.avviktypeId === AVVIKTYPE_ENTREPRENOR && user.isInternBymbruker()
        ? ['IkkeBehandlet', 'AvvikMottatt'].indexOf(status.key) >= 0
        : ['IkkeBehandlet', 'FerdigBehandlet'].indexOf(status.key) >= 0
    )
    .map((status) => {
      return {
        value: status.id,
        label: getAvvikStatustekst(status.id),
      };
    });

  const onVisBekreftEndreStatusDialog = (status) => {
    if (status.value === item.status) return;
    setValid(true);

    setNyStatus({
      id: status.value,
    });
    setBekreftStatusEndringSynlig(true);
  };

  const onEndreStatus = () => {
    // validate
    const isValid =
      !isNullOrWhitespace(nyStatus.id) &&
      !isNullOrWhitespace(nyStatus.kommentar);

    setValid(isValid);
    if (isValid) {
      item.status = nyStatus.id;
      onSetStatus(nyStatus);
      setTimeout(() => {
        onUpdateEndringslogg(item);
      }, 1500);
      setBekreftStatusEndringSynlig(false);
    }
  };

  const setStatusKommentar = (kommentar) => {
    setNyStatus({
      ...nyStatus,
      kommentar,
    });
  };

  const slettAvvik = () => {
    const isValid = !isNullOrWhitespace(slettAvvikKommentar);
    setValidForSletting(isValid);
    if (isValid) {
      onSetStatus({
        id: avvikStatuskoder.find((status) => status.key === 'Slettet').id,
        kommentar: slettAvvikKommentar,
      });
      setBekreftSlettSynlig(false);
      onClose();
    }
  };

  return (
    <div className="avvik-blade" style={{ display: item ? 'block' : 'none' }}>
      <div className="button-row">
        {!isSlettet && (user.isInternBymbruker() || isEksternInnmelder) && (
          <Button
            color="link"
            onClick={() => history.push(`/innmelding/endre/${item.id}`)}
          >
            <FaPen />
            <Translate id="kontrollpanel.avvik.rediger-innmelding" />
          </Button>
        )}
        <Button color="link" onClick={() => onClose()}>
          <Translate id="kontrollpanel.avvik.lukk" />
          <FaTimes />
        </Button>
      </div>
      <div className="button-row">
        <Button
          color="secondary"
          className="avvikblade-pdf-btn"
          onClick={async () => {
            setIsGeneratingPdf(true);
            const avvikId = item.id;
            try {
              const response = await httpClient.getAvvikPdf(avvikId);
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement('a');
              const getCurrentDateTime = () => {
                const now = new Date();
                const year = now.getFullYear();
                const month = (now.getMonth() + 1).toString().padStart(2, '0');
                const day = now.getDate().toString().padStart(2, '0');
                const hours = now.getHours().toString().padStart(2, '0');
                const minutes = now.getMinutes().toString().padStart(2, '0');
                const seconds = now.getSeconds().toString().padStart(2, '0');
                return `${year}${month}${day}_${hours}${minutes}${seconds}`;
              };
              link.href = url;
              link.setAttribute(
                'download',
                `Avvik_${getCurrentDateTime()}_${item.nummer}.pdf`
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } catch (error) {
              console.error('Error:', error);
            } finally {
              setIsGeneratingPdf(false);
            }
          }}
          disabled={isGeneratingPdf}
        >
          {isGeneratingPdf && <FaSpinner className="spin" />}
          {!isGeneratingPdf && <FaPrint />}
          <span className={isGeneratingPdf ? 'text-gray' : ''}>Skriv ut</span>
        </Button>
      </div>
      <div className="item-header">
        {!item.adresse && (
          <Button color="link">
            {
              localeContext.currentLocale.messages[
                'kontrollpanel.avvik.ingen-adresse'
              ]
            }
          </Button>
        )}
        {item.adresse && (
          <a
            href={getGoogleUrl(item.adresse)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item && getFormattedAddress(item.adresse)}
          </a>
        )}
        <span>{(item && getFormattedDate(item.utfort)) || '-'}</span>
      </div>
      <div className="item-body">
        <div className="section">
          <table>
            <tbody>
              <tr className="item-tidspunkt">
                <th>
                  <Translate id="kontrollpanel.avvik.klokken" />:
                </th>
                <td>{(item && getFormattedTime(item.utfort)) || '-'}</td>
              </tr>
              <tr className="item-kategori">
                <th>
                  <Translate id="kontrollpanel.avvik.kategori" />:
                </th>
                <td>
                  {item && item.kategoriId && (
                    <Translate id={`kategori.${item.kategoriId}`} />
                  )}
                </td>
              </tr>
              <tr className="item-arbeidstype">
                <th>
                  <Translate id="kontrollpanel.avvik.type-arbeid" />:
                </th>
                <td>
                  {item && item.arbeidstypeId && (
                    <Translate id={`kontrolltype.${item.arbeidstypeId}`} />
                  )}
                </td>
              </tr>
              <tr className="item-feiltype">
                <th>
                  <Translate id="kontrollpanel.avvik.type-feil" />:
                </th>
                <td>
                  {item && item.feiltypeId && (
                    <Translate id={`feiltype.${item.feiltypeId}`} />
                  )}
                </td>
              </tr>
              <tr className="item-feiltype">
                <th>
                  <Translate id="kontrollpanel.avvik.type-vei" />:
                </th>
                <td>
                  {item &&
                    item.veitypeId &&
                    getFormattedTextForVeitype(item.veitypeId)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="section">
          <h6>
            <Translate id="kontrollpanel.avvik.kommentar" />
          </h6>
          <span>{(item && item.kommentar) || '-'}</span>
        </div>
        <div className="section">
          <div className="avvik-status-container">
            <h6>
              <Translate id="kontrollpanel.avvik.status" />
            </h6>
            <StatusBadge
              status={item.status}
              isEksternInnmelder={isEksternInnmelder}
            />
          </div>
          &nbsp;
          {loading && (
            <div className="status-loading">
              <PulseLoader
                sizeUnit="px"
                size={10}
                color="#000"
                loading={loading}
              />
            </div>
          )}
          {!loading && kanEndreStatus && (
            <>
              <UncontrolledDropdown className="avvik-status-dropdown">
                <DropdownToggle caret>
                  <Translate id={`kontrollpanel.avvik-status.${item.status}`} />
                </DropdownToggle>
                <DropdownMenu>
                  {statusDropdownlistItems
                    .filter((statusItem) => {
                      return item.status !== statusItem.value;
                    })
                    .map((statusItem) => {
                      return (
                        <DropdownItem
                          key={Math.random()}
                          active={item.status === statusItem.label}
                          onClick={() =>
                            onVisBekreftEndreStatusDialog(statusItem)
                          }
                        >
                          {statusItem.label}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </UncontrolledDropdown>
              <AvvikBladeConfirmModal
                bekreftStatusEndringSynlig={bekreftStatusEndringSynlig}
                onEndreStatus={onEndreStatus}
                setBekreftStatusEndringSynlig={setBekreftStatusEndringSynlig}
                nyStatus={nyStatus}
                valid={valid}
                setStatusKommentar={setStatusKommentar}
              />
            </>
          )}
          {/* <Button>Lagre</Button> */}
        </div>
        <div className="section">
          <h6>
            <Translate id="kontrollpanel.avvik.alvorlighetsgrad" />
          </h6>
          {/* <span>{item && item.botgrunnlag ? 'Alvorlig' : '-'}</span> */}
          <FormGroup check className="alvorlighetsgrad-checkbox">
            <Label check>
              <Input
                type="checkbox"
                disabled
                checked={item && item.botgrunnlag}
              />
              <Translate id="kontrollpanel.avvik.alvorlig" />
            </Label>
          </FormGroup>
        </div>
        <div className="section">
          <table>
            <tbody>
              <tr className="item-meldingsnummer">
                <th>
                  <Translate id="kontrollpanel.avvik.meldingsnummer" />:
                </th>
                <td>{(item && item.nummer) || '-'}</td>
              </tr>
              <tr className="item-seksjon">
                <th>
                  <Translate id="kontrollpanel.avvik.seksjon" />:
                </th>
                <td>
                  {item && item.seksjonId && (
                    <Translate id={`seksjon.${item.seksjonId}`} />
                  )}
                </td>
              </tr>
              <tr className="item-omrade">
                <th>
                  <Translate id="kontrollpanel.avvik.omrade" />:
                </th>
                <td>{(item && item.omrade) || '-'}</td>
              </tr>
              <tr className="item-bydel">
                <th>
                  <Translate id="kontrollpanel.avvik.bydel" />:
                </th>
                <td>{(item && item.bydel) || '-'}</td>
              </tr>
              <tr className="item-innmelder">
                <th>
                  <Translate id="kontrollpanel.avvik.innmelder" />:
                </th>
                <td>{(item && item.opprettetAvNavn) || '-'}</td>
              </tr>
              <tr className="item-entreprenor">
                <th>
                  <Translate id="kontrollpanel.avvik.entreprenor" />:
                </th>
                <td>
                  {item &&
                    item.kontrakt &&
                    item.kontrakt.selskapId &&
                    statiskeData.entreprenorselskaper &&
                    getFormattedEntrerprenor(item.kontrakt.selskapId)}
                </td>
              </tr>
              <tr className="item-kontrakt">
                <th>
                  <Translate id="kontrollpanel.avvik.kontrakt" />:
                </th>
                <td>
                  {item &&
                    item.kontraktId &&
                    statiskeData.kontrakter &&
                    getFormattedKontrakt(item.kontraktId)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="section item-images">
          <h6>
            <Translate id="kontrollpanel.avvik.vedlagte-bilder" />
          </h6>
          <div className="image-container">
            {(item && item.bilder && item.bilder.length > 0 && (
              <BildeModal
                bilder={item.bilder.map((b) => b.id)}
                avvikId={item.id}
              />
            )) || (
              <span>
                &lt;
                <Translate id="kontrollpanel.avvik.ingen-bilder" />
                &gt;
              </span>
            )}
          </div>
        </div>
        <div className="section item-map ">
          <BymMap
            defaultCenter={[item.latitude, item.longitude]}
            defaultZoom={15}
            scrollWheelZoom={false}
            touchZoom={false}
            dragging={false}
            hideKartlag
            hideKartlagvelger
            markers={[
              {
                position: [item.latitude, item.longitude],
                title: item.adresse,
                key: item.id,
                icon: getAvvikIcon(item),
              },
            ]}
          />
        </div>
        {item.historikk && item.historikk.length > 0 && (
          <div className="section item-history">
            <h6>
              <Button
                className={`avvik-historikk-expand-button avvik-historikk-caret-${
                  statushistorikkIsExpanded ? 'down' : 'left'
                }`}
                onClick={() =>
                  setStatushistorikkIsExpanded(!statushistorikkIsExpanded)
                }
              >
                <Translate id="kontrollpanel.avvik.statushistorikk" />
              </Button>
            </h6>
            {statushistorikkIsExpanded &&
              item.historikk &&
              item.historikk
                .filter((endring) => endring.endretFelt === 'Status')
                .sort((a, b) => {
                  return sortByStringDesc(a.endret, b.endret);
                })
                .map((endring) => {
                  return (
                    <Statushistorikk
                      key={Math.random()}
                      endretAv={endring.endretAvNavn}
                      kommentar={endring.kommentar}
                      tidspunkt={endring.endret}
                      // opprinneligVerdi={getAvvikStatustekst(
                      //   endring.opprinneligVerdi
                      // )}
                      opprinneligVerdiId={endring.opprinneligVerdi}
                      // nyVerdi={getAvvikStatustekst(endring.nyVerdi)}
                      nyVerdiId={endring.nyVerdi}
                      isEksternInnmelder={isEksternInnmelder}
                    />
                  );
                })}
          </div>
        )}
        {!isSlettet && (user.isInternBymbruker() || isEksternInnmelder) && (
          <div className="delete-avvik">
            <Button
              outline
              className="delete-avvik-btn"
              onClick={() => setBekreftSlettSynlig(true)}
            >
              <span>
                <Translate id="kontrollpanel.avvik.slett-innmelding" />
              </span>
              <FaTrashAlt style={{ marginLeft: '5px' }} />
            </Button>
            <ConfirmModal
              tittel={
                <Translate id="kontrollpanel.avvik-slette-modal-bekreft.tittel" />
              }
              tekst={`${localeContext.currentLocale.messages['kontrollpanel.avvik-slette-modal-bekreft.text']} ${item.nummer}?`}
              bekreftKnappetekst={
                <Translate id="kontrollpanel.avvik-slette-modal-bekreft.confirmButton" />
              }
              avbrytKnappetekst={
                <Translate id="kontrollpanel.avvik-slette-modal-bekreft.cancelButton" />
              }
              show={bekreftSlettSynlig}
              onConfirm={() => slettAvvik(item)}
              onCancel={() => setBekreftSlettSynlig(false)}
            >
              <br />
              <CommentsInput
                maxChars={300}
                label={
                  <Translate id="kontrollpanel.avvik-slette-modal-bekreft.kommentar" />
                }
                placeholder={
                  localeContext.currentLocale.messages[
                    'kontrollpanel.avvik-slette-modal-bekreft.hjelpetekst'
                  ]
                }
                text={slettAvvikKommentar}
                validState={validForSletting}
                autofocus
                onChange={(text) => setSlettAvvikKommentar(text)}
              />
            </ConfirmModal>
          </div>
        )}
      </div>
    </div>
  );
};
AvvikBlade.propTypes = {
  item: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onSetStatus: PropTypes.func.isRequired,
  onUpdateEndringslogg: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  statiskeData: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withRouter(AvvikBlade);
