import * as Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const COOKIE_KEY = 'enk_token';

export const getCachedToken = () => {
  const cookie = Cookies.get(COOKIE_KEY);
  return cookie;
};

export const saveUserToken = (tokenData) => {
  Cookies.set(COOKIE_KEY, tokenData.access_token, {
    path: '/',
    expires: tokenData.expires_in,
  });
};

export const deleteUserToken = () => Cookies.remove(COOKIE_KEY, { path: '/' });

export const decodeToken = (jwt) => {
  try {
    return jwtDecode(jwt);
  } catch (err) {
    return null;
  }
};
