import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Label } from 'reactstrap';
import moment from 'moment';
import Translate from '../../Locale/Translate';
import { UserContext } from '../../User/UserProvider';
import StatusBadge from '../Common/StatusBadge.tsx';

function Statushistorikk(props) {
  const {
    endretAv,
    kommentar,
    tidspunkt,
    opprinneligVerdiId,
    nyVerdiId,
    isEksternInnmelder,
  } = props;
  const { user } = useContext(UserContext);
  const [isExpanded, setIsExpanded] = useState(false);

  const formatDateTime = (dt) => moment(dt).format('DD.MM.YYYY - [Kl.]HH:mm');
  return (
    <>
      <div className="avvik-historikk-element">
        <div className="avvik-historikk-element-navn">
          <h5>
            <Button
              className={`avvik-historikk-tittel-button avvik-historikk-caret-${
                isExpanded ? 'down' : 'left'
              }`}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {endretAv}{' '}
            </Button>
          </h5>
          <div className="avvik-historikk-rolle-og-tidspunkt">
            <div>
              <h6>{user.getPrimaryRoleName()}</h6>
            </div>
            <div className="avvik-historikk-element-tidspunkt">
              {formatDateTime(tidspunkt)}
            </div>
          </div>
        </div>
        <Label
          className={`avvik-historikk-element-kommentar ${
            isExpanded ? '' : 'truncate'
          }`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {kommentar}
        </Label>
        <div className="avvik-historikk-element-status">
          <div>
            <Translate id="kontrollpanel.avvik-blade.status-historikk.tidligere-status-label" />
            :{' '}
            <Translate
              id={`kontrollpanel.avvik-status.${opprinneligVerdiId}`}
            />
            {/* {opprinneligVerdi} */}
          </div>
          <StatusBadge
            status={nyVerdiId}
            isEksternInnmelder={isEksternInnmelder}
          />
        </div>
      </div>
    </>
  );
}

Statushistorikk.propTypes = {
  endretAv: PropTypes.string.isRequired,
  kommentar: PropTypes.string.isRequired,
  tidspunkt: PropTypes.string.isRequired,
  opprinneligVerdiId: PropTypes.string.isRequired,
  nyVerdiId: PropTypes.string.isRequired,
  isEksternInnmelder: PropTypes.string.isRequired,
};

export default Statushistorikk;
