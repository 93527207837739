/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { UserContext } from '../../User/UserProvider';

const WhoAmI = () => {
  const { user } = useContext(UserContext);
  return (
    <>
      {!user ? (
        <span>Ikke logget inn</span>
      ) : (
        <>
          <h1>Who am I?</h1>
          <h3>{user.getName()}</h3>
          <div>
            <span>UserId: {user.getId()}</span>
          </div>
          <span>Roller:</span>
          <ul>
            {user.isBymeldingAdmin() && <li>BymeldingAdmin</li>}
            {user.isEnkAdmin() && <li>EntreprenorkontrollAdmin</li>}
            {user.isForvalter() && <li>Forvalter</li>}
            {user.isKontrollor() && <li>Kontrollor</li>}
            {user.isEntreprenor() && <li>Entreprenor</li>}
          </ul>
        </>
      )}
    </>
  );
};

export default WhoAmI;
