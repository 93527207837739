import React from 'react';
import { Link } from 'react-router-dom';
import Translate from '../../Locale/Translate';
import { getBymeldingClientUrl } from '../../utils/environment';

const Unauthorized = () => {
  const bymeldingClient = getBymeldingClientUrl();
  return (
    <div
      style={{
        margin: '40px',
        paddingTop: '40px',
        width: 'auto',
      }}
    >
      <p
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
        }}
      >
        <Translate
          defaultMessage="Denne tjenesten er under utvikling."
          id="unauth.unauthMessage_1"
        />
      </p>
      <p
        style={{
          fontSize: '20px',
        }}
      >
        <Translate
          defaultMessage="Noen brukere må derfor logge inn flere steder får å få tilgang til driftssiden."
          id="unauth.unauthMessage_2"
        />
        <br />
        <br />
        <a
          href={`${bymeldingClient}/login`}
          style={{
            paddingTop: '40px',
            width: 'auto',
            textDecoration: 'underline',
          }}
        >
          <Translate
            defaultMessage="Logg inn i Bymelding her."
            id="unauth.loginBymelding"
          />
        </a>
        <br />
        <br />
        <Link
          style={{
            paddingTop: '40px',
            width: 'auto',
            textDecoration: 'underline',
          }}
          to="login"
        >
          <Translate
            defaultMessage="Logg inn i Drift og vedlikehold her."
            id="unauth.loginEnk"
          />
        </Link>
      </p>
    </div>
  );
};

export default Unauthorized;
