import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from 'reactstrap';
import {
  adresseLookup,
  geoLookup,
  KartverketAdresse,
} from '../../utils/kartverketUtils';

type Props = {
  name: string;
  inputClassName: string | undefined;
  placeholder: string | undefined;
  onSuggestSelect: (adresse: KartverketAdresse) => void;
  onLoading: (loading: boolean) => void;
  value: string | undefined;
};

const KartverketGeoSuggest = (props: Props) => {
  const {
    name,
    inputClassName,
    placeholder,
    onSuggestSelect,
    onLoading,
    value,
  } = props;

  const [currentValue, setCurrentValue] = useState<string>();
  const [soketreff, setSoketreff] = useState<KartverketAdresse[]>([]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const onAdresseLookup = useCallback(async (s: string) => {
    try {
      onLoading(true);
      const treff = await adresseLookup(s);
      setSoketreff(treff ?? []);
    } finally {
      onLoading(false);
    }
  }, []);

  const debouncedAdresseLookup = useMemo(
    () => debounce(onAdresseLookup, 500),
    [onAdresseLookup]
  );

  const onAddressInputChange = async (s: string): Promise<void> => {
    setCurrentValue(s);

    if (s.length <= 2) {
      setSoketreff([]);
      return;
    }

    await debouncedAdresseLookup(s);
  };

  const onAddressSelected = (adresse: KartverketAdresse) => {
    onSuggestSelect(adresse);
    setSoketreff([]);
  };

  return (
    <>
      <Input
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        value={currentValue}
        onChange={(e: { target: { value: string } }) =>
          onAddressInputChange(e.target.value)
        }
      />
      {soketreff.length > 0 && (
        <div className="geosuggest-soketreff-dropdown">
          {soketreff.map((adresse) => {
            return (
              <div
                key={adresse.adressetekst}
                role="button"
                className="geosuggest-soketreff-item"
                onClick={() => onAddressSelected(adresse)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') onAddressSelected(adresse);
                }}
                tabIndex={0}
              >
                {adresse.adressetekst}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default KartverketGeoSuggest;
