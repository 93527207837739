import getEnvironmentVariable from '../utils/environment';

// API
export const login = 'token';
export const validateToken = 'validertoken';

export const statiskeData = () => `statiske-data`;
export const avvikId = (id) => `avvik/${id}`;
export const postAvvik = () => `avvik`;
export const getAvvikListe = () => `avvik`;
export const getPublikummeldinger = () => `publikummeldinger`;
export const downloadAvvikVedleggBase64 = (id, vedleggId) =>
  `avvik/${id}/vedlegg/${vedleggId}/base64`;
export const getKartData = (
  datoFra,
  datoTil,
  bounds,
  arbeidstyper,
  kontraktnummere,
  omrade,
  bydel
) =>
  `kart/sporingsdata?fra=${datoFra}&til=${datoTil}&bounds=${bounds}&${arbeidstyper}&${kontraktnummere}&driftsomrade=${omrade}&bydel=${bydel}`;

export const sporDetaljer = (kilde, sporId, latitude, longitude) =>
  `kart/sporingsdata/${kilde}/${sporId}?latitude=${latitude}&longitude=${longitude}`;

export const forbrukForSpor = (kilde, sporId) =>
  `ressursbruk/spor/${kilde}/${sporId}`;

export const sporingsdataInfo = () => `kart/sporingsdatainfo/`;

export const bydelOgDriftsinfo = (latitude, longitude) =>
  `kart/bydeler/sok/inkluderDriftsinfo?latitude=${latitude}&longitude=${longitude}`;

const convertStatistikkfilterToQueryStringParam = (filter) => {
  const qsParam = Object.keys(filter)
    .filter((key) => {
      return (
        filter[key] !== null &&
        Array.isArray(filter[key]) &&
        filter[key].length > 0 &&
        filter[key][0].id !== '00000000-0000-0000-0000-000000000000'
      );
    })
    .map((key) => {
      return filter[key].map((item) => `${key}=${item.id}`).join('&');
    });

  return qsParam.join('&');
};
export const getStatistikkVinterdrift = (datoFra, datoTil) =>
  `statistikk/vinterdrift?fra=${datoFra}&til=${datoTil}`;

export const getStatistikkAvvik = (datoFra, datoTil, filter) =>
  `statistikk/avvik/manedlig?fra=${datoFra}&til=${datoTil}&${convertStatistikkfilterToQueryStringParam(
    filter
  )}`;

export const getStatistikkAvvikPrBydel = (datoFra, datoTil, filter) => {
  return `statistikk/avvik/bydel?fra=${datoFra}&til=${datoTil}&${convertStatistikkfilterToQueryStringParam(
    filter
  )}`;
};

export const getStatistikkAvvikPrEntreprenor = (datoFra, datoTil, filter) =>
  `statistikk/avvik/entreprenor?fra=${datoFra}&til=${datoTil}&${convertStatistikkfilterToQueryStringParam(
    filter
  )}`;

export const getStatistikkAvviksfeiltypePrBydel = (datoFra, datoTil, filter) =>
  `statistikk/avvik/feiltype/bydel?fra=${datoFra}&til=${datoTil}&${convertStatistikkfilterToQueryStringParam(
    filter
  )}`;

export const getStatistikkAvviksfeiltypePrEntreprenor = (
  datoFra,
  datoTil,
  filter
) =>
  `statistikk/avvik/feiltype/entreprenor?fra=${datoFra}&til=${datoTil}&${convertStatistikkfilterToQueryStringParam(
    filter
  )}`;

export const getStatistikkAvvikOgKontroller = (datoFra, datoTil) =>
  `statistikk/avvik-og-kontroller/manedlig?fra=${datoFra}&til=${datoTil}`;

export const getStatistikkTiltakPrEntreprenor = (datoFra, datoTil, filter) =>
  `statistikk/tiltak/entreprenor?fra=${datoFra}&til=${datoTil}&${convertStatistikkfilterToQueryStringParam(
    filter
  )}`;

export const getStatistikkRessursbrukPrMateriale = (datoFra, datoTil, filter) =>
  `statistikk/ressursbruk/materiale?fra=${datoFra}&til=${datoTil}&${convertStatistikkfilterToQueryStringParam(
    filter
  )}`;

export const getStatistikkMaskinerIDriftPrBydel = (datoFra, datoTil, filter) =>
  `statistikk/maskiner/bydel?fra=${datoFra}&til=${datoTil}&${convertStatistikkfilterToQueryStringParam(
    filter
  )}`;
export const getStatistikkKontrollerPrMnd = (datoFra, datoTil, filter) =>
  `statistikk/kontroller/manedlig?fra=${datoFra}&til=${datoTil}&${convertStatistikkfilterToQueryStringParam(
    filter
  )}`;

export const getHistoriskeVaerdata = (tidspunkt) =>
  `historiskvaer?tidspunkt=${tidspunkt}`;

export const getEntreprenorselskaper = () => `admin/entreprenorselskap`;
export const getKontrakter = () => `admin/kontrakter`;
export const startBymeldingImport = () => `admin/bymeldingimport`;
export const putEntreprenorselskapSporingId = () =>
  `admin/entreprenorselskap/sporingId`;
export const getEndpoints = () => `admin/endpoints`;
export const getHealthcheck = () => `healthcheck`;
export const getVarslinger = (sisteSettId) =>
  `varslinger?sisteSettId=${sisteSettId}`;
export const putVarslingerIndex = () => `varslinger/sistelest`;

export const getMeldingsstatistikk = (datoFra, datoTil) =>
  `meldinger/statistikk?fra=${datoFra || ''}&til=${datoTil || ''}`;

// Client internal urls
export const clientUrls = {
  login: '/login',
  kart: '/kart',
  statistikk: '/statistikk',
  kontrollPanel: (fra, til, id) => `/kontrollpanel/${fra}/${til}/${id}`,
};

export const entrepenorkontrollUrl = `${getEnvironmentVariable(
  'REACT_APP_URL_SERVICE'
)}/api/`;

export const bymeldingStatistikkUrl = `${getEnvironmentVariable(
  'REACT_APP_URL_STATISTIKK_SERVICE'
)}/api/`;

// Notificationsservice
export const notificationServiceBaseUrl = `${getEnvironmentVariable(
  'REACT_APP_URL_BYMELDING_NOTIFICATION_SERVICE'
)}/api/`;
export const getNotificationServiceData = (klientKey) =>
  `varsel?klientKey=${klientKey}`;

// Autentiserings service
export const autentiseringBaseUrl = `${getEnvironmentVariable(
  'REACT_APP_URL_AUTENTISERING_SERVICE'
)}/api/`;
