import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import LoginForm from './LoginForm';

import { loginUser } from '../../state/login';
import { toastError } from '../../state/toast';

function getNextPathName(location) {
  return location.state && location.state.nextPathname
    ? location.state.nextPathname
    : '/';
}

class LoginPage extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    history: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
  };

  token = '';

  handleLogin = (payload) => {
    const { login, history, location } = this.props;
    login(payload).then(() => history.push(getNextPathName(location)));
  };

  render() {
    return (
      <div style={{ maxWidth: '400px', margin: '50px auto' }}>
        <LoginForm login={this.handleLogin} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ login: loginUser, toastError }, dispatch);
}

export default withRouter(connect(null, mapDispatchToProps)(LoginPage));
