/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import axios from 'axios';
import LoginProvider from './features/Login/LoginProvider';
import LoggProvider from './features/Common/LoggProvider';
import ScrollToTop from './features/Common/ScrollToTop';
import LocaleProvider from './Locale/LocaleProvider';

import store from './state/store';
import './index.css';
import './index.scss';
import App from './App';

import * as serviceWorker from './serviceWorker';
import UserProvider from './User/UserProvider';
import StatiskeDataProvider from './features/Common/StatiskeDataProvider';
import DisplayStateProvider from './features/Common/DisplayStateProvider';
import { entrepenorkontrollUrl } from './api/urls';

axios.defaults.baseURL = entrepenorkontrollUrl;

ReactDOM.render(
  <Provider store={store}>
    <LoggProvider>
      <BrowserRouter>
        <LoginProvider>
          <UserProvider>
            <ScrollToTop>
              <LocaleProvider>
                <StatiskeDataProvider>
                  <DisplayStateProvider>
                    <App />
                  </DisplayStateProvider>
                </StatiskeDataProvider>
              </LocaleProvider>
            </ScrollToTop>
          </UserProvider>
        </LoginProvider>
      </BrowserRouter>
    </LoggProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
