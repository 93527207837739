import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { filter } from 'lodash';
import moment from 'moment';
import { PulseLoader } from 'react-spinners';
import { FaTimes } from 'react-icons/fa';
import InnmeldingFormMap from './InnmeldingFormMap';
import Translate from '../../Locale/Translate';
import DatetimeInput from '../DatetimeInput/DatetimeInput';
import GeoSuggestInput from '../GeoSuggestInput/GeoSuggestInput';
import './Hva.css';
import ImageUploader from '../ImageUploader/ImageUploader';
import CommentsInput from '../CommentsInput/CommentsInput';
import StatusInput from '../StatusInput/StatusInput';
import InnmeldingFormHva from './InnmeldingFormHva';
import { UserContext } from '../../User/UserProvider';
import ConfirmModal from '../Common/ConfirmModal';
import RequiredInputStar from './requiredInputStar';
import InnmeldingFormEntreprenorselskap from './InnmeldingFormEntreprenorselskap';
import { avvikStatuskoder } from '../../constants/status';

const InnmeldingForm = (props) => {
  const {
    innmelding,
    setInnmelding,
    onSave,
    saving,
    header,
    validState,
    statiskeData,
  } = props;
  const { user } = useContext(UserContext);

  const [bekreftAvbrytSynlig, setBekreftAvbrytSynlig] = useState(false);

  const isKladd =
    innmelding.status ===
    avvikStatuskoder.find((status) => status.key === 'Kladd').id;

  const onSaveDraftClick = () => {
    onSave(avvikStatuskoder.find((status) => status.key === 'Kladd').id);
  };

  const onSaveClick = () => {
    onSave(
      isKladd || innmelding.status === undefined || innmelding.status === null // sett status hvis avvik er kladd eller nytt
        ? avvikStatuskoder.find((status) => status.key === 'IkkeBehandlet').id
        : innmelding.status
    );
  };

  return (
    <div>
      <Row className="innmelding-form">
        <Col
          xs={{ size: 12, order: 2 }}
          lg={{ size: 4, order: 1 }}
          className="innmelding-form-input"
        >
          <h2>{header}</h2>
          <span className="input-required-desc">
            <RequiredInputStar />
            <span className="innmelding-form-support-text">
              <Translate
                id="innmelding-form.reqired-info"
                defaultMessage="Må fylles ut"
              />
            </span>
          </span>
          <h3 className="innmelding-form-header">
            <Translate
              id={
                user.isEntreprenor()
                  ? 'innmelding-form.dateTimeLabel.entreprenor'
                  : 'innmelding-form.dateTimeLabel'
              }
            />
            <RequiredInputStar />
          </h3>
          <div>
            <DatetimeInput
              datoLabel={<Translate id="innmelding-form.dateLabel" />}
              tidLabel={<Translate id="innmelding-form.timeLabel" />}
              handleChange={(e) => setInnmelding({ ...innmelding, utfort: e })}
              value={innmelding.utfort}
              validState={validState.utfort}
              isValidDate={(current) => moment(current).isBefore(moment())}
            />
          </div>
          <h3 className="innmelding-form-header">
            <Translate
              id={
                user.isEntreprenor()
                  ? 'innmelding-form.whatHeader.entreprenor'
                  : 'innmelding-form.whatHeader'
              }
            />
            <RequiredInputStar />
          </h3>
          <InnmeldingFormHva
            statiskeData={statiskeData}
            setInnmelding={setInnmelding}
            innmelding={innmelding}
            validState={validState}
          />
          <h3 className="innmelding-form-header">
            <Translate id="innmelding-form.placeHeader" />
            <RequiredInputStar />
          </h3>
          <div className="innmelding-form-support-text">
            <Translate
              id="innmelding-form.placeSubHeader"
              defaultMessage="Angi en nøyaktig plassering."
            />
          </div>
          <div id="innmelding-form-angi-sted">
            <InnmeldingFormMap
              innmelding={innmelding}
              setInnmelding={setInnmelding}
            />
            <Translate id="innmelding-form.placePlaceholder">
              {(msg) => (
                <GeoSuggestInput
                  placeholder={msg}
                  value={{
                    lat: innmelding.latitude,
                    lng: innmelding.longitude,
                    adresse: innmelding.adresse,
                    bydel: innmelding.bydel,
                    omrade: innmelding.omrade,
                  }}
                  validState={validState.sted}
                  setInnmelding={(value) => {
                    setInnmelding({ ...innmelding, ...value });
                  }}
                />
              )}
            </Translate>
          </div>
          <div id="innmelding-form-angi-img" className="innmelding-form-header">
            <ImageUploader
              label={
                <>
                  <Translate id="innmelding-form.addPictureHeader" />
                  <RequiredInputStar />
                </>
              }
              ingress={
                <span className="innmelding-form-support-text">
                  <Translate id="innmelding-form.pictureIngress" />
                </span>
              }
              images={innmelding.bilder}
              maxCount={5}
              onImagesAdded={(bilder) =>
                setInnmelding({
                  ...innmelding,
                  bilder: [...innmelding.bilder, ...bilder],
                })
              }
              onImageRemoved={(bilde) =>
                setInnmelding({
                  ...innmelding,
                  slettedeBilder: bilde.id
                    ? [...(innmelding.slettedeBilder || []), bilde.id]
                    : null,
                  bilder: filter(innmelding.bilder, (b) => {
                    return b !== bilde;
                  }),
                })
              }
              validState={validState.bilder}
            />
          </div>
          <div id="innmelding-form-angi-comment">
            <h3 className="innmelding-form-header">
              <Translate id="innmelding-form.commentLabel" />
            </h3>
            <CommentsInput
              label=" "
              maxChars={300}
              text={innmelding.kommentar}
              onChange={(text) =>
                setInnmelding({ ...innmelding, kommentar: text })
              }
            />
          </div>

          {!user.isEntreprenor() && (
            <>
              <InnmeldingFormEntreprenorselskap
                innmelding={innmelding}
                setInnmelding={setInnmelding}
                validState={validState}
                statiskeData={statiskeData}
              />
              <div id="innmelding-form-angi-status">
                <h3 className="innmelding-form-header">
                  <Translate id="innmelding-form.statusLabel" />
                </h3>
                <StatusInput
                  label={<Translate id="innmelding-form.basisForTicket" />}
                  botgrunnlag={innmelding.botgrunnlag}
                  onChange={(botgrunnlag) => {
                    setInnmelding({ ...innmelding, botgrunnlag });
                  }}
                />
              </div>
            </>
          )}
          <div className="innmelding-form-footer">
            <div className="innmelding-form-footer-spinner">
              <PulseLoader
                loading={saving}
                size={10}
                sizeUnit="px"
                color="#000"
              />
            </div>
            <div className="innmelding-form-footer-buttons">
              {(innmelding.id === undefined || isKladd) && (
                <Button
                  onClick={onSaveDraftClick}
                  color="secondary"
                  disabled={saving}
                >
                  <Translate id="innmelding.submitKladdButton" />
                </Button>
              )}
              <Button onClick={onSaveClick} color="primary" disabled={saving}>
                {innmelding.id === undefined || isKladd ? (
                  <Translate id="innmelding.submitButton" />
                ) : (
                  <Translate id="innmelding.submitChangesButton" />
                )}
              </Button>
              <Button
                onClick={() => setBekreftAvbrytSynlig(true)}
                color="link"
                disabled={saving}
              >
                <FaTimes />
                <Translate id="innmelding.avbrytButton" />
              </Button>
              <ConfirmModal
                tittel={<Translate id="innmelding-avbryt-bekreft.tittel" />}
                tekst={<Translate id="innmelding-avbryt-bekreft.text" />}
                bekreftKnappetekst={
                  <Translate id="innmelding-avbryt-bekreft.confirmButton" />
                }
                avbrytKnappetekst={
                  <Translate id="innmelding-avbryt-bekreft.cancelButton" />
                }
                show={bekreftAvbrytSynlig}
                onConfirm={() => window.history.back()}
                onCancel={() => setBekreftAvbrytSynlig(false)}
              />
            </div>
          </div>
        </Col>
        <Col
          xs={{ hidden: true }}
          lg={{ size: 8, order: 2 }}
          id="innmelding-form-right"
        >
          <div id="innmelding-form-map">
            <InnmeldingFormMap
              innmelding={innmelding}
              setInnmelding={setInnmelding}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

InnmeldingForm.propTypes = {
  innmelding: PropTypes.shape({}).isRequired,
  validState: PropTypes.shape({}).isRequired,
  header: PropTypes.node,
  setInnmelding: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  statiskeData: PropTypes.shape({}).isRequired,
};

InnmeldingForm.defaultProps = {
  header: null,
};

export default InnmeldingForm;
