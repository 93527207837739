export const BYMELDINGADMIN = '2acd6c6e-1f75-4a39-b225-56f6169e2bfa';

// BymRoller
export const BYMSERVICEAVDELINGADMIN = 'd03ab2e2-c978-46d4-b20a-e3a107bab1a9';
export const BYMSERVICEAVDELING = '614ea746-965b-428d-861b-81d8d5de561f';
export const BYMENTREPRENORADMIN = '5bd99df0-55f2-4888-946c-e7a826eee3b4';
export const BYMENTREPRENOR = '3cfbfb8c-7f32-4c47-a4a5-7e55dc0690f3';
// export const BYMANONYMPUBLIKUM = 'afdabe68-23ba-45cb-9b6d-6cab5412bd07';
// export const BYMSAMARBEIDSPARTNER = 'bcd07735-801d-4a5c-8a57-1a8b0076dbbd';
// export const BYMSAMARBEIDSPARTNERADMIN = 'e73274f9-fe7b-42e3-8734-917f4eeec416';
// export const BYMPUBLIKUM = '26477DB0-D06F-4E24-8335-996BAA8ABB47';

// EnkRoller
export const ENTREPRENORKONTROLLADMIN = '63690d61-452b-4dda-9b5e-6bfd69f116cf';
export const FORVALTER = '637b3c47-567f-41f3-9ee1-d50d82305835';
export const KONTROLLOR = 'c2125e33-7da0-4f92-b196-070529b0acfe';
export const ENTREPRENOR = '2c5539cc-26de-4419-bea8-80e8447150aa';
export const DASHBOARD = '164ef76b-6756-4101-85bc-25a52aff82be';
