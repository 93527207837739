/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import SearchableDropdownList from '../SearchableDropdownList/SearchableDropdownList';
import Translate from '../../Locale/Translate';
import enkStore from '../../utils/localStoreUtils';
import { UserContext } from '../../User/UserProvider';
import './Hva.css';
import { LocaleContext } from '../../Locale/LocaleProvider';

const InnmeldingFormHva = ({
  statiskeData,
  setInnmelding,
  innmelding,
  validState,
  isSearchable,
}) => {
  const { user } = useContext(UserContext);
  const localeContext = useContext(LocaleContext);
  const [kategoriOptions, setKategoriOptions] = useState();
  const [kontrolltypeOptions, setKontrolltypeOptions] = useState();
  const [feiltypeOptions, setFeiltypeOptions] = useState();
  const [seksjonOptions, setSeksjonOptions] = useState();
  const rootId = '00000000-0000-0000-0000-000000000000';

  const elementsToOptions = (
    elements,
    parentId,
    translateKeyGroup,
    avviktypeId
  ) => {
    if (!elements || !parentId) return undefined;

    return elements
      .filter(
        (e) =>
          e.parentIds.includes(parentId) &&
          (!avviktypeId || e.avviktypeIds.includes(avviktypeId))
      )
      .map((e) => {
        return {
          value: e.id,
          key: e.navn,
          label:
            (translateKeyGroup &&
              localeContext.currentLocale.messages[
                `${translateKeyGroup}.${e.id}`
              ]) ||
            e.navn,
        };
      });
  };

  const onSeksjonChange = (sek) => {
    enkStore.setItem('innmelding.valgtSeksjon', JSON.stringify(sek.value));
    enkStore.setItem('innmelding.valgtKategori', '');
    const i = {
      ...innmelding,
      seksjonId: sek.value,
      kategoriId: null,
      arbeidstypeId: null,
      feiltypeId: null,
    };
    setInnmelding(i);
  };

  const onKategoriChange = (kat) => {
    enkStore.setItem('innmelding.valgtKategori', JSON.stringify(kat.value));
    setInnmelding({
      ...innmelding,
      kategoriId: kat.value,
      arbeidstypeId: null,
      feiltypeId: null,
    });
  };

  const onKontrolltypeChange = (valgtKontrolltype) => {
    setInnmelding({
      ...innmelding,
      arbeidstypeId: valgtKontrolltype.value,
      feiltypeId: null,
    });
  };

  const onFeiltypeChange = (valgtFeiltype) => {
    setInnmelding({ ...innmelding, feiltypeId: valgtFeiltype.value });
  };

  const populateDropdowns = (inn) => {
    if (!(statiskeData && statiskeData.feiltypeHierarki)) return;
    const hierarki = statiskeData.feiltypeHierarki;

    if (inn.seksjonId) {
      const kategorier = elementsToOptions(
        hierarki.kategorier,
        inn.seksjonId,
        'kategori'
      );
      setKategoriOptions(kategorier || []);
      if (!inn.kategoriId && kategorier && kategorier.length === 1) {
        onKategoriChange(kategorier[0]);
      }
      if (inn.kategoriId && kategorier) {
        const kontrolltyper = elementsToOptions(
          hierarki.kontrolltyper,
          inn.kategoriId,
          'kontrolltype'
        );
        setKontrolltypeOptions(kontrolltyper || []);
        if (!inn.arbeidstypeId && kontrolltyper && kontrolltyper.length === 1) {
          onKontrolltypeChange(kontrolltyper[0]);
        }
        if (inn.arbeidstypeId && kontrolltyper) {
          const feiltyper = elementsToOptions(
            hierarki.feiltyper,
            inn.arbeidstypeId,
            'feiltype',
            inn.avviktypeId
          );
          setFeiltypeOptions(feiltyper || []);
          if (!inn.feiltypeId && feiltyper && feiltyper.length === 1) {
            onFeiltypeChange(feiltyper[0].value);
          }
        }
      }
    }
  };

  const sortOptions = (options) => {
    if (!options) return undefined;
    return options.sort((a, b) => {
      if (a.key === 'Annet') return 1;
      if (b.key === 'Annet') return -1;
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  };

  useEffect(() => {
    populateDropdowns(innmelding);
  }, [innmelding, seksjonOptions, localeContext.currentLocale]);

  useEffect(() => {
    const so =
      statiskeData &&
      statiskeData.feiltypeHierarki &&
      statiskeData.feiltypeHierarki.seksjoner &&
      elementsToOptions(
        statiskeData.feiltypeHierarki.seksjoner,
        rootId,
        'seksjon'
      );
    setSeksjonOptions(so);
  }, [statiskeData, localeContext.currentLocale]);

  const veitypeOptions =
    statiskeData &&
    statiskeData.feiltypeHierarki &&
    statiskeData.feiltypeHierarki.veityper &&
    statiskeData.feiltypeHierarki.veityper.map((v) => {
      return {
        value: v.id,
        key: v.navn,
        label:
          localeContext.currentLocale.messages[`veitype.${v.id}`] || v.navn,
      };
    });

  return (
    <div id="innmelding-form-hva">
      <>
        <Translate id="innmelding-form.sectionDropdown">
          {(ph) => (
            <SearchableDropdownList
              label={<Translate id="innmelding-form.sectionLabel" />}
              options={sortOptions(seksjonOptions)}
              onChange={onSeksjonChange}
              value={innmelding.seksjonId}
              validState={validState.seksjon}
              placeholder={ph}
              isSearchable={isSearchable}
            />
          )}
        </Translate>
        <Translate id="innmelding-form.categoryDropdown">
          {(ph) => (
            <SearchableDropdownList
              label={<Translate id="innmelding-form.categoryLabel" />}
              options={sortOptions(kategoriOptions)}
              onChange={onKategoriChange}
              value={innmelding.kategoriId}
              validState={validState.kategori}
              placeholder={ph}
              isSearchable={isSearchable}
            />
          )}
        </Translate>
        <Translate id="innmelding-form.whatDropdown">
          {(ph) => (
            <SearchableDropdownList
              label={
                user.isEntreprenor() ? (
                  <Translate id="innmelding-form.whatLabel.entreprenor" />
                ) : (
                  <Translate id="innmelding-form.whatLabel" />
                )
              }
              options={sortOptions(kontrolltypeOptions)}
              onChange={onKontrolltypeChange}
              value={innmelding.arbeidstypeId}
              validState={validState.arbeidstype}
              placeholder={ph}
              isSearchable={isSearchable}
            />
          )}
        </Translate>
        <Translate id="innmelding-form.typeDropdown">
          {(ph) => (
            <SearchableDropdownList
              label={
                user.isEntreprenor() ? (
                  <Translate id="innmelding-form.typeLabel.entreprenor" />
                ) : (
                  <Translate id="innmelding-form.typeLabel" />
                )
              }
              options={sortOptions(feiltypeOptions)}
              onChange={onFeiltypeChange}
              value={innmelding.feiltypeId}
              validState={validState.feiltype}
              placeholder={ph}
              isSearchable={isSearchable}
            />
          )}
        </Translate>
        <Translate id="innmelding-form.typeVeiDropdown">
          {(ph) => (
            <SearchableDropdownList
              label={<Translate id="innmelding-form.typeVeiLabel" />}
              options={sortOptions(veitypeOptions)}
              onChange={(e) =>
                setInnmelding({ ...innmelding, veitypeId: e.value })
              }
              value={innmelding.veitypeId}
              validState={validState.veitype}
              placeholder={ph}
              isSearchable={isSearchable}
            />
          )}
        </Translate>
      </>
    </div>
  );
};

InnmeldingFormHva.propTypes = {
  statiskeData: PropTypes.shape({}),
  setInnmelding: PropTypes.func.isRequired,
  innmelding: PropTypes.shape({}),
  validState: PropTypes.shape({}),
  isSearchable: PropTypes.bool,
};

InnmeldingFormHva.defaultProps = {
  statiskeData: null,
  innmelding: null,
  validState: null,
  isSearchable: true,
};

export default InnmeldingFormHva;
