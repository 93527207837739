import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loginUserFromStore as loginUserFromStoreAction } from '../../state/login';

class LoginProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    loginUserFromStore: PropTypes.func.isRequired,
    history: PropTypes.shape({}).isRequired,
  };

  state = {
    loginComplete: false,
  };

  componentDidMount() {
    const { loginUserFromStore } = this.props;
    loginUserFromStore()
      .then(() => {
        this.setState({ loginComplete: true });
      })
      .catch(() => {
        this.setState({ loginComplete: true });
      });
  }

  render() {
    const { children } = this.props;
    const { loginComplete } = this.state;
    return loginComplete ? children : 'Laster...';
  }
}

const mapDispatchToProps = (dispatch) => ({
  loginUserFromStore: () => dispatch(loginUserFromStoreAction()),
});

export default withRouter(connect(null, mapDispatchToProps)(LoginProvider));
