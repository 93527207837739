import { connect } from 'react-redux';
import Header from './Header';
import { logoutUser } from '../../state/login';

// const mapStateToProps = state => ({
//   login: state.login,
// });

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutUser()),
});

export default connect(null, mapDispatchToProps)(Header);
