import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../User/UserProvider';

const AdminRoute = (props) => {
  const { component: Comp, ...rest } = props;
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={() => {
        if (user && user.isEnkAdmin()) {
          return <Comp {...props} />;
        }
        if (user && !user.isEnkAdmin()) {
          return <div>Access denied</div>;
        }
        return (
          <Redirect
            to={{
              pathname: '/unauth',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
export default AdminRoute;
