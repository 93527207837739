import React from 'react';
import { Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import PublicRoute from './features/Routes/PublicRoute';
import PrivateRoute from './features/Routes/PrivateRoute';
import AdminRoute from './features/Routes/AdminRoute';
import Header from './features/Navigation/HeaderContainer';
import BreadCrumbContainer from './features/Breadcrumb/BreadcrumbContainer';
import InnmeldingPage from './features/Innmelding/InnmeldingPage';
import KontrollpanelContainer from './features/Kontrollpanel/KontrollpanelContainer';
import MapPage from './features/Driftskart/MapPage';
import Healthcheck from './features/Common/Healthcheck';
import LoginPage from './features/Login/LoginPage';
import Landingsside from './features/Landingsside/Landingsside';
import StatistikkContainer from './features/Statistikk/StatistikkContainer';
import WhoAmI from './features/WhoAmI/WhoAmI';
import Toast from './features/Common/Toast/toast';
import EntreprenorselskapAdmin from './features/Admin/EntreprenorselskapAdmin';
import Unauthorized from './features/Common/Unauthorized';

import './App.css';

import 'leaflet/dist/leaflet.css';
import Endpoints from './features/Admin/Endpoints';
import Helse from './features/Admin/Helse';
import DashboardContainer from './features/Dashboard/DashboardContainer';
import DashboardMonitorMapContainer from './features/Dashboard/DashboardMonitorMapContainer';
import DashboardDriftsstatistikkAktiveMaskiner from './features/Dashboard/DashboardDriftsstatistikkAktiveMaskiner';
import DashboardDriftsstatistikkUtfortTiltak from './features/Dashboard/DashboardDriftsstatistikkUtfortTiltak';
import InternalBymUserRoute from './features/Routes/InternalBymUserRoute';

const App = () => {
  let installPrompt = null;
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    installPrompt = e;
    console.log('TCL: App -> installPrompt', installPrompt);
  });

  // const [state, dispatch] = useReducer(reducer, null, null);
  return (
    <div
      className={`app ${
        window.location.search.indexOf('monitor-mode') > 0 ? 'monitor-mode' : ''
      }`}
    >
      <Header />
      <BreadCrumbContainer />
      <Toast />
      <Container id="page-container" fluid>
        <Switch>
          <PrivateRoute exact path="/" component={Landingsside} />
          <PublicRoute path="/login" component={LoginPage} />
          <PrivateRoute path="/kart/:fradato?/:tildato?" component={MapPage} />
          <PrivateRoute path="/innmelding" component={InnmeldingPage} />
          <PrivateRoute
            path="/kontrollpanel/:datofra?/:datotil?/:avvikId?"
            component={KontrollpanelContainer}
          />
          <PrivateRoute
            exact
            path="/statistikk/:gruppe?"
            component={StatistikkContainer}
          />
          <PrivateRoute exact path="/whoami" component={WhoAmI} />
          <InternalBymUserRoute
            path="/admin/dashboard/:monitor?"
            component={DashboardContainer}
          />
          <InternalBymUserRoute
            path="/admin/monitor_map/:monitor?"
            component={DashboardMonitorMapContainer}
          />
          <InternalBymUserRoute
            path="/admin/monitor_statistikk_drift_maskiner/:monitor?"
            component={DashboardDriftsstatistikkAktiveMaskiner}
          />
          <InternalBymUserRoute
            path="/admin/monitor_statistikk_drift_tiltak/:monitor?"
            component={DashboardDriftsstatistikkUtfortTiltak}
          />
          <AdminRoute path="/healthcheck" component={Healthcheck} />
          <AdminRoute
            path="/admin/entreprenorselskaper"
            component={EntreprenorselskapAdmin}
          />
          <AdminRoute path="/admin/endpoints" component={Endpoints} />
          <AdminRoute path="/admin/helse" component={Helse} />
          <PublicRoute path="/unauth" component={Unauthorized} />
        </Switch>
      </Container>
    </div>
  );
};

export default App;
