import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Calendar from 'react-calendar';
import { UserContext } from '../../User/UserProvider';
import { getStandardFilterFormattedDateTime } from '../../utils/dateUtils';
import Translate from '../../Locale/Translate';

import 'react-calendar/dist/Calendar.css';
import Varsel from '../VarselDrift/VarselDrift';
import featureIsEnabled, {
  FEATURE_NOTIFICATION_VARSEL,
} from '../../utils/featureManagement';

const Landingsside = ({ history }) => {
  const { user } = useContext(UserContext);

  return (
    <div className="landingsside-container">
      <div className="box-container">
        <div className="header-boks">
          <h1>
            {user.isEntreprenor() ? (
              <Translate
                id="startPage.pageTitle.entreprenor"
                defaultMessage="Entreprenør"
              />
            ) : (
              <Translate
                id="startPage.pageTitle"
                defaultMessage="Entreprenørkontroll"
              />
            )}
          </h1>
        </div>
        <div className="varsel-boks">
          {featureIsEnabled(FEATURE_NOTIFICATION_VARSEL) && <Varsel />}
        </div>
        <div
          className="boks driftskart-boks"
          role="button"
          onClick={() => history.push('/kart')}
          onKeyPress={() => {
            history.push('/kart');
          }}
          tabIndex={0}
        >
          <div className="info-div">
            <div className="info-div-sub">
              <h2>
                <Translate
                  id="startPage.driftskart"
                  defaultMessage="Driftskart"
                />
              </h2>
              <p>
                {user.isEntreprenor() ? (
                  <Translate
                    id="startPage.driftskartBody.entreprenor"
                    defaultMessage="Oversikt over ditt selskaps drift i Oslo."
                  />
                ) : (
                  <Translate
                    id="startPage.driftskartBody"
                    defaultMessage="Oversikt over entreprenører og deres drift i Oslo."
                  />
                )}
              </p>
            </div>
          </div>
        </div>
        <div
          className="boks registrer-boks"
          role="button"
          onClick={() => history.push('/innmelding/ny')}
          onKeyPress={() => {
            history.push('/innmelding/ny');
          }}
          tabIndex={0}
        >
          <h2>
            {user.isEntreprenor() ? (
              <Translate
                id="startPage.kontroll.entreprenor"
                defaultMessage="Registrer avvik"
              />
            ) : (
              <Translate
                id="startPage.kontroll"
                defaultMessage="Registrer en kontroll av Oslo"
              />
            )}
          </h2>
          <p>
            {user.isEntreprenor() ? (
              <Translate
                id="startPage.kontrollBody.entreprenor"
                defaultMessage="Registrer avvik i forbindelse med driften"
              />
            ) : (
              <Translate
                id="startPage.kontrollBody"
                defaultMessage="Kontroller driften i byen"
              />
            )}
          </p>
          <div className="info-div">
            <div className="info-div-sub">
              {user.isEntreprenor() ? (
                <Translate
                  id="startPage.kontrollBtn.entreprenor"
                  defaultMessage="Registrer avvik"
                />
              ) : (
                <Translate
                  id="startPage.kontrollBtn"
                  defaultMessage="Start kontroll"
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="boks oversikt-boks"
          role="button"
          onClick={() => history.push('/kontrollpanel')}
          onKeyPress={() => {
            history.push('/kontrollpanel');
          }}
          tabIndex={0}
        >
          <h4>
            {user.isEntreprenor() ? (
              <Translate
                id="startPage.oversikt.entreprenor"
                defaultMessage="Oversikt"
              />
            ) : (
              <Translate
                id="startPage.oversikt"
                defaultMessage="Kontroll oversikt"
              />
            )}
          </h4>
          <p>
            {user.isEntreprenor() ? (
              <Translate
                id="startPage.oversiktBody.entreprenor"
                defaultMessage="Få en oversikt over innmeldte avvik"
              />
            ) : (
              <Translate
                id="startPage.oversiktBody"
                defaultMessage="Få en oversikt over dine innmeldte kontroller"
              />
            )}
          </p>
        </div>
        <div className="kalender-boks">
          <Calendar
            value={new Date()}
            maxDate={new Date()}
            onChange={(date) => {
              const fra = getStandardFilterFormattedDateTime(date[0]);
              const til = getStandardFilterFormattedDateTime(date[1]);
              history.push({
                pathname: `/kart/${fra}/${til}`,
                state: { from: history.location.pathname },
              });
            }}
            returnValue="range"
            className="landingsside-kalender"
            // maxDetail="month"
            minDetail="year"
            // selectRange
          />
        </div>
        <div
          className="boks statistikk-boks"
          role="button"
          onClick={() => history.push('/statistikk')}
          onKeyPress={() => {
            history.push('/statistikk');
          }}
          tabIndex={0}
        >
          <h4>
            <Translate id="startPage.statistikk" defaultMessage="Statistikk" />
          </h4>
          <p>
            <Translate
              id="startPage.statistikkBody"
              defaultMessage="Se ressursbruk status mm."
            />
          </p>
        </div>
      </div>
    </div>
  );
};

Landingsside.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(Landingsside);
