import {
  BYMELDINGADMIN,
  ENTREPRENORKONTROLLADMIN,
  ENTREPRENOR,
  KONTROLLOR,
  FORVALTER,
  BYMSERVICEAVDELINGADMIN,
  BYMSERVICEAVDELING,
  BYMENTREPRENORADMIN,
  BYMENTREPRENOR,
  DASHBOARD,
} from '../constants/roles';

export default class User {
  constructor(id, name, primaryRole, roles) {
    this._id = id;
    this._name = name;
    this._roles = roles;
    this._primaryRole = primaryRole; // prosessrolle
  }

  static fromToken(token) {
    if (!token || !token.ProsessrolleId) return undefined;

    const roller = token.ProsessrolleRolleId
      ? JSON.parse(token.ProsessrolleRolleId)
      : [];

    roller.push(token.ProsessrolleId);
    return new User(token.brukerId, token.sub, token.ProsessrolleId, roller);
  }

  getName = () => this._name;

  getId = () => this._id;

  getPrimaryRole = () => this._primaryRole;

  getPrimaryRoleName = () => {
    const roller = {
      [BYMELDINGADMIN]: 'Bymelding admin',
      [ENTREPRENORKONTROLLADMIN]: 'Entreprenørkontroll admin',
      [ENTREPRENOR]: 'Entreprenør',
      [KONTROLLOR]: 'Kontrollør',
      [FORVALTER]: 'Forvalter',
      [BYMSERVICEAVDELINGADMIN]: 'Serviceavdeling admin',
      [BYMSERVICEAVDELING]: 'Serviceavdeling',
      [BYMENTREPRENORADMIN]: 'Entreprenør admin',
      [BYMENTREPRENOR]: 'Entreprenør',
    };
    return roller[this._primaryRole];
  };

  getRoles = () => this._roles;

  hasRole = (role) => this._roles.includes(role);

  isEntreprenor = () => this.hasRole(ENTREPRENOR);

  isKontrollor = () => this.hasRole(KONTROLLOR);

  isForvalter = () => this.hasRole(FORVALTER);

  isEnkAdmin = () => this.hasRole(ENTREPRENORKONTROLLADMIN);

  isBymeldingAdmin = () => this.hasRole(BYMELDINGADMIN);

  isBymServiceavdelingAdmin = () => this.hasRole(BYMSERVICEAVDELINGADMIN);

  isBymServiceavdeling = () => this.hasRole(BYMSERVICEAVDELING);

  isDashboard = () => this.hasRole(DASHBOARD);

  isBymEntreprenorAdmin = () => this.hasRole(BYMENTREPRENORADMIN);

  isBymEntreprenor = () => this.hasRole(BYMENTREPRENOR);

  isInternBymbruker = () =>
    this.isKontrollor() ||
    this.isForvalter() ||
    this.isEnkAdmin() ||
    this.isBymeldingAdmin() ||
    this.isBymServiceavdelingAdmin() ||
    this.isBymServiceavdeling() ||
    this.isDashboard();
}
