import { combineReducers } from 'redux';
import loginReducer from './login';
import toast from './toast';
import kartdata from './kartdata';
import kartlag from './kartlag';
import filter from './filter';
import avvik from './avvik';
import kontrollpanel from './kontrollpanel';
import spordetaljer from './spordetaljer';
import statistikk from './statistikk';
import statiskeData from './statiskeData';
import kontroller from './kontroller';
import dashboard from './dashboard';

const rootReducer = combineReducers({
  login: loginReducer,
  toast,
  statiskeData,
  kartdata,
  filter,
  avvik,
  kontrollpanel,
  spordetaljer,
  statistikk,
  kartlag,
  kontroller,
  dashboard,
});

export default rootReducer;
