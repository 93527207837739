import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Translate from '../../Locale/Translate';
import { getNorwegianLocalTime } from '../../utils/dateUtils';
import InnmeldingForm from './InnmeldingForm';
import InnmeldingFormMobil from './innmeldingMobilvisning/InnmeldingFormMobil';
import {
  createAvvikFormData,
  validateAvvik,
  validateKladd,
} from '../../utils/formUtils';
import {
  AVVIKTYPE_ENTREPRENOR,
  AVVIKTYPE_KONTROLLOR,
} from '../../utils/avvikUtils';
import httpClient from '../../api/httpClient';
import enkStore from '../../utils/localStoreUtils';
import 'moment/locale/nb';
import { UserContext } from '../../User/UserProvider';
import { avvikStatuskoder } from '../../constants/status';
import { wmslayers } from '../../utils/mapUtils';
import {
  addKartlag as addKartlagAction,
  resetKartlag as resetKartlagAction,
} from '../../state/kartlag';

const NyInnmelding = ({
  toastError,
  statiskeData,
  history,
  match,
  addKartlag,
  resetKartlag,
}) => {
  const isMobile = window.innerWidth <= 490;

  const { user } = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [validation, setValidation] = useState({ valid: true });
  const [innmelding, setInnmelding] = useState({
    innmeldingstype: '',
    avviktypeId: user.isEntreprenor()
      ? AVVIKTYPE_ENTREPRENOR
      : AVVIKTYPE_KONTROLLOR,
    status: null,
    utfort: getNorwegianLocalTime().format(),
    seksjonId: null,
    latitude: null,
    longitude: null,
    adresse: null,
    bydel: null,
    omrade: null,
    kategoriId: null,
    arbeidstypeId: null,
    feiltypeId: null,
    bilder: [],
    kommentar: '',
    botgrunnlag: false,
    veitypeId: null,
  });

  const applySavedState = async () => {
    try {
      const previousSeksjonSelection = JSON.parse(
        await enkStore.getItem('innmelding.valgtSeksjon')
      );
      const previousKategoriSelection = JSON.parse(
        await enkStore.getItem('innmelding.valgtKategori')
      );

      if (previousSeksjonSelection || previousKategoriSelection) {
        setInnmelding({
          ...innmelding,
          seksjonId: previousSeksjonSelection,
          kategoriId: previousKategoriSelection,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    applySavedState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = {
    when: 'innmelding_1',
    what: 'innmelding_2',
    where: 'innmelding_3',
    comment: 'innmelding_4',
  };

  const [step, setStep] = useState(steps.when);

  useEffect(() => {
    if (match.params.step && match.params.step !== step) {
      setStep(match.params.step);
    } else history.replace(`/innmelding/ny/${steps.when}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.step]);

  const isKladd = (statusId) =>
    avvikStatuskoder.find((status) => status.key === 'Kladd').id === statusId;

  useEffect(() => {
    if (!validation.valid) {
      const validate = isKladd(innmelding.status)
        ? validateKladd(innmelding, user)
        : validateAvvik(innmelding, user);

      setValidation(validate);
    }
  }, [innmelding, user, validation.valid]);

  const saveAvvik = async (status) => {
    innmelding.status = status;
    const validate = isKladd(status)
      ? validateKladd(innmelding, user)
      : validateAvvik(innmelding, user);
    if (validate.valid) {
      setSaving(true);
      const avvikForm = createAvvikFormData(innmelding);
      try {
        await httpClient.postAvvikForm(avvikForm);
        history.push('/innmelding/bekreftet');
      } catch (err) {
        toastError(err);
        setValidation(validate);
      } finally {
        setSaving(false);
      }
    }
    return setValidation(validate);
  };

  const applyKartlagOgSetInnmelding = (oppdatertInnmelding) => {
    resetKartlag();

    if (
      oppdatertInnmelding.arbeidstypeId ===
      '88804f61-a85b-4164-8d93-910999c22bf7'
    ) {
      // Bru
      addKartlag(wmslayers.find((layer) => layer.id === 'Broer'));
    } else if (
      oppdatertInnmelding.arbeidstypeId ===
      '47b7699d-42ce-4754-a195-92415ca78ae6'
    ) {
      // Sluktømming
      addKartlag(wmslayers.find((layer) => layer.id === 'Slukredigering BYM'));
    }

    setInnmelding(oppdatertInnmelding);
  };

  return (
    <>
      {isMobile ? (
        <InnmeldingFormMobil
          header={
            user.isEntreprenor() ? (
              <Translate id="innmelding.pageHeader.entreprenor" />
            ) : (
              <Translate id="innmelding.pageHeader" />
            )
          }
          innmelding={innmelding}
          setInnmelding={applyKartlagOgSetInnmelding}
          onSave={saveAvvik}
          saving={saving}
          validState={validation}
          statiskeData={statiskeData}
          step={step}
          steps={steps}
          navLink="/innmelding/ny/"
        />
      ) : (
        <InnmeldingForm
          header={
            user.isEntreprenor() ? (
              <Translate id="innmelding.pageHeader.entreprenor" />
            ) : (
              <Translate id="innmelding.pageHeader" />
            )
          }
          innmelding={innmelding}
          setInnmelding={applyKartlagOgSetInnmelding}
          onSave={saveAvvik}
          saving={saving}
          validState={validation}
          statiskeData={statiskeData}
        />
      )}
    </>
  );
};

NyInnmelding.propTypes = {
  statiskeData: PropTypes.shape({}).isRequired,
  toastError: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  addKartlag: PropTypes.func.isRequired,
  resetKartlag: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  statiskeData: state.statiskeData,
});

const mapDispatchToProps = (dispatch) => ({
  addKartlag: (kartLag) => dispatch(addKartlagAction(kartLag)),
  resetKartlag: () => dispatch(resetKartlagAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NyInnmelding));
