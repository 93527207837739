/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  FormGroup,
  InputGroup,
  Input,
  Button,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import KartverketGeoSuggest from './KartverketGeoSuggestInput';
import Translate from '../../Locale/Translate';
import httpClient from '../../api/httpClient';
import minLokasjon from '../../images/finn-min-lokasjon.svg';
import { geoLookup } from '../../utils/kartverketUtils';

const GeoSuggestInput = (props) => {
  const { placeholder, value, validState, setInnmelding, onChange } = props;

  const [adressesokLoadingVisible, setAdressesokLoadingVisible] =
    useState(false);

  useEffect(() => {
    if (value.lat && value.lng && value.adresse === null) {
      geoLookup(value.lat, value.lng).then((result) => {
        const v = {
          latitude: value.lat,
          longitude: value.lng,
          adresse: result.adressetekst,
        };
        if (!isEmpty(result)) {
          if (setInnmelding) {
            setInnmelding(v);
          } else {
            onChange(v);
          }
        }
      });
    }
  }, [value]);

  const onAngiStedChange = async (location) => {
    // get bydel
    if (setInnmelding) {
      setAdressesokLoadingVisible(true);
      try {
        await httpClient
          .getBydelOgDriftsomrade(location.latitude, location.longitude)
          .then((resp) => {
            setInnmelding({
              latitude: location.latitude,
              longitude: location.longitude,
              adresse: location.adresse,
              bydel: resp.bydel,
              omrade: resp.driftsomrade,
            });
          });
      } finally {
        setAdressesokLoadingVisible(false);
      }
    } else {
      onChange(location);
    }
  };

  const onSuggestSelected = (suggestion) => {
    onAngiStedChange({
      latitude: suggestion ? suggestion.lat : null,
      longitude: suggestion ? suggestion.lon : null,
      adresse:
        suggestion && suggestion.adressetekst ? suggestion.adressetekst : null,
    });
  };

  const onMyLocationClicked = () => {
    navigator.geolocation.getCurrentPosition(
      (res) => {
        onAngiStedChange({
          latitude: res.coords.latitude,
          longitude: res.coords.longitude,
          adresse: null,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      {
        enableHighAccuracy: true,
      }
    );
  };

  return (
    <div>
      <FormGroup>
        <div className="geosuggest-input">
          <InputGroup>
            <KartverketGeoSuggest
              name="geosuggest-input"
              inputClassName={`form-control geosuggest-form-control ${
                validState && ' invalid'
              }`}
              placeholder={placeholder}
              onSuggestSelect={onSuggestSelected}
              onLoading={(e) => setAdressesokLoadingVisible(e ?? false)}
              value={value.adresse}
            />

            <InputGroupAddon
              addonType="append"
              className={`gesosuggest-form-control-addon ${
                validState && ' invalid'
              }`}
            >
              <InputGroupText>
                <span>
                  <FaSearch />
                </span>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>

          <Button
            className="geosuggest-my-location-button"
            onClick={onMyLocationClicked}
            color="secondary"
          >
            <img src={minLokasjon} alt="Finn min posisjon" />
          </Button>
        </div>
        <PulseLoader
          sizeUnit="px"
          size={8}
          color="#000"
          loading={adressesokLoadingVisible}
        />
        {validState && <div className="invalid-geo-feedback">{validState}</div>}
        {!adressesokLoadingVisible && (
          <span>
            <i>
              {value.bydel && (
                <span className="innmelding-form-support-text">
                  <Translate id="innmelding-form.cityAreaLabel" />:{' '}
                  {value.bydel}
                  {', '}
                </span>
              )}
              {value.omrade && (
                <span className="innmelding-form-support-text">
                  <Translate id="innmelding-form.contractArea" />:{' '}
                  {value.omrade}
                </span>
              )}
            </i>
          </span>
        )}
      </FormGroup>
    </div>
  );
};

GeoSuggestInput.propTypes = {
  placeholder: PropTypes.string,
  setInnmelding: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.shape({}),
  validState: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

GeoSuggestInput.defaultProps = {
  placeholder: '',
  value: null,
  validState: null,
  setInnmelding: null,
  onChange: null,
};

export default GeoSuggestInput;
