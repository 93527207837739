import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FaUser, FaTachometerAlt } from 'react-icons/fa';
import Translate from '../../Locale/Translate';
import { LocaleContext } from '../../Locale/LocaleProvider';
import byvaapen from '../../images/byvaapenet.svg';
import { getBymeldingClientUrl } from '../../utils/environment';
import { UserContext } from '../../User/UserProvider';
import Varslinger from '../Varslinger/Varslinger';
import IfMediaQueryMatch from './IfMediaQueryMatch';
import {
  isAnyFeatureEnabled,
  FEATURE_DASHBOARD_STATISTIKK_ENDRINGSTYPER,
  FEATURE_DASHBOARD_STATISTIKK_AVVISTE_MELDINGSTYPER,
} from '../../utils/featureManagement';

const Header = ({ logout }) => {
  const localeContext = useContext(LocaleContext);
  const { user } = useContext(UserContext);
  const bymeldingClient = getBymeldingClientUrl();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Navbar className="bym-navbar" color="light" light expand="xl" fixed="top">
      <NavbarBrand href="/">
        <img
          className="brand-byvaapen"
          src={byvaapen}
          alt="Oslo kommunes Byvåpen"
          title="Byvåpen"
        />
        <span className="brand-title">
          <Translate id="header.headerTitle" />
          {/* <span
            style={{
              padding: '5px',
              margin: '5px',
              border: '1px solid transparent',
              borderRadius: '0.25rem',
              color: ' #7e5302',
              backgroundColor: '#fceccd',
              borderColor: '#fbe4b9',
            }}
          >
            <Translate id="header.BetaTitle" />
          </span> */}
        </span>
      </NavbarBrand>

      <IfMediaQueryMatch mediaQuery="(max-width: 991px)">
        <Nav className="varslinger-mobil">
          <Varslinger />
        </Nav>
      </IfMediaQueryMatch>
      <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          {!user ? (
            <>
              <NavItem>
                <a className="nav-link" href={`${bymeldingClient}/login`}>
                  {/* <NavLink
                  tag={Link}
                  to="/login"
                  onClick={() => setIsOpen(!isOpen)}
                > */}
                  <Translate
                    id="header.loginNavLink"
                    defaultMessage="Logg inn"
                  />
                </a>
              </NavItem>
              {/* </NavI
                </NavLink>
              tem> */}
            </>
          ) : (
            <>
              <NavItem active>
                <NavLink tag={Link} to="/" onClick={() => setIsOpen(!isOpen)}>
                  <Translate id="header.drifttNavItem" defaultMessage="Drift" />
                </NavLink>
              </NavItem>
              {(user.isBymEntreprenorAdmin() || user.isBymEntreprenor()) && (
                <NavItem>
                  <a
                    className="nav-link"
                    href={`${bymeldingClient}/entreprenormeldinger`}
                  >
                    <Translate
                      id="header.MeldingerNavItem"
                      defaultMessage="Meldinger"
                    />
                  </a>
                </NavItem>
              )}
              {user.isBymEntreprenorAdmin() && (
                <>
                  <NavItem>
                    <a
                      className="nav-link"
                      href={`${bymeldingClient}/entreprenorbrukere`}
                    >
                      <Translate
                        id="header.BrukereNavItem"
                        defaultMessage="Brukere"
                      />
                    </a>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle className="nav-link" nav caret>
                      <Translate
                        id="header.KontrakterTitle"
                        defaultMessage="Kontrakter"
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <a
                          className="nav-link--dropdown-item"
                          href={`${bymeldingClient}/entreprenorkontrakter`}
                        >
                          <Translate
                            id="header.KontrakterTitle"
                            defaultMessage="Kontrakter"
                          />
                        </a>
                      </DropdownItem>
                      <DropdownItem>
                        <a
                          className="nav-link--dropdown-item"
                          href={`${bymeldingClient}/globalressursbruk`}
                        >
                          <Translate
                            id="header.RessursbrukTitle"
                            defaultMessage="Ressursbruk"
                          />
                        </a>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              )}
              {(user.isBymServiceavdeling() ||
                user.isBymServiceavdelingAdmin()) && (
                <NavItem>
                  <a
                    className="nav-link"
                    href={`${bymeldingClient}/saksbehandlermeldinger`}
                  >
                    <Translate
                      id="header.MeldingerNavItem"
                      defaultMessage="Meldinger"
                    />
                  </a>
                </NavItem>
              )}
              {/* 
              isBymeldingAdmin
              isBymServiceavdelingAdmin
              isBymServiceavdeling
              isBymEntreprenorAdmin
              isBymEntreprenor 
              */}
              {(user.isBymeldingAdmin() ||
                user.isBymServiceavdelingAdmin()) && (
                <NavItem>
                  <a
                    className="nav-link"
                    href={`${bymeldingClient}/serviceavdelingen`}
                  >
                    <Translate
                      id="header.ServiceavdelingenNavItem"
                      defaultMessage="Serviceavdelingen"
                    />
                  </a>
                </NavItem>
              )}
              {user.isBymeldingAdmin() && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="nav-link">
                    <Translate
                      id="header.EntreprenørerNavItem"
                      defaultMessage="Entreprenører"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <a
                        className="nav-link--dropdown-item"
                        href={`${bymeldingClient}/entreprenorer`}
                      >
                        <Translate
                          id="header.EntreprenørerNavItem"
                          defaultMessage="Entreprenører"
                        />
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a
                        className="nav-link--dropdown-item"
                        href={`${bymeldingClient}/prosesskoder`}
                      >
                        <Translate
                          id="header.ProsesskoderNavItem"
                          defaultMessage="Prosesskoder"
                        />
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a
                        className="nav-link--dropdown-item"
                        href={`${bymeldingClient}/avtaler`}
                      >
                        <Translate
                          id="header.AvtalerkoderNavItem"
                          defaultMessage="Avtaler"
                        />
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {/* isBymeldingAdmin
                  isBymServiceavdelingAdmin
                  isBymServiceavdeling
                  isBymEntreprenorAdmin
                  isBymEntreprenor */}
              {(user.isBymeldingAdmin() ||
                user.isBymServiceavdelingAdmin() ||
                user.isBymServiceavdeling() ||
                user.isBymEntreprenorAdmin()) && (
                <NavItem>
                  <a className="nav-link" href={`${bymeldingClient}/rapporter`}>
                    <Translate
                      id="header.RapporterNavItem"
                      defaultMessage="Rapporter"
                    />
                  </a>
                </NavItem>
              )}
              <IfMediaQueryMatch mediaQuery="(min-width: 992px)">
                <Varslinger />
              </IfMediaQueryMatch>
              {isAnyFeatureEnabled([
                FEATURE_DASHBOARD_STATISTIKK_ENDRINGSTYPER,
                FEATURE_DASHBOARD_STATISTIKK_AVVISTE_MELDINGSTYPER,
              ]) &&
                user.isInternBymbruker() && (
                  <NavItem>
                    <NavLink tag={Link} to="/admin/dashboard">
                      <FaTachometerAlt size={21} />
                    </NavLink>
                  </NavItem>
                )}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <span>
                    <FaUser />
                    {user.getName()}
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      logout();
                      setIsOpen(!isOpen);
                    }}
                  >
                    <Translate
                      id="header.logoutNavItem"
                      defaultMessage="Logg ut"
                    />
                  </DropdownItem>
                  {localeContext &&
                    map(localeContext.currentLocale.available, (i, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => localeContext.onChangeLanguage(i)}
                      >
                        {i}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

Header.propTypes = {
  user: PropTypes.shape({}),
  logout: PropTypes.func.isRequired,
};

Header.defaultProps = {
  user: null,
};

export default Header;
