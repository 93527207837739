import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import {
  ENTREPENORKONTROLL_SERVICE_ID,
  BYMELDING_SERVICE_ID,
} from '../../utils/environment';

import './LoginForm.css';

const LoginForm = ({ login }) => {
  const [values, updateValues] = useState({
    epost: '',
    passord: '',
    serviceId: ENTREPENORKONTROLL_SERVICE_ID,
  });

  const selectInput = [
    {
      value: ENTREPENORKONTROLL_SERVICE_ID,
      name: 'Entrepenørkontroll',
      id: ENTREPENORKONTROLL_SERVICE_ID,
    },
    {
      value: BYMELDING_SERVICE_ID,
      name: 'Bymelding',
      id: BYMELDING_SERVICE_ID,
    },
  ];

  const keyPressed = (key) => {
    const code = key && (key.keyCode || key.which);
    if (code === 13) {
      // enter clicked, attempt login
      login(values);
    }
  };

  return (
    <>
      <FormGroup row>
        <Label for="brukernavn" sm={2}>
          *Brukernavn
        </Label>
        <Input
          type="email"
          name="brukernavn"
          id="Brukernavn"
          placeholder="Brukernavn"
          value={values.epost}
          onChange={(e) => updateValues({ ...values, epost: e.target.value })}
          onKeyPress={(k) => keyPressed(k)}
        />
      </FormGroup>
      <FormGroup row>
        <Label for="passord" sm={2}>
          *Passord
        </Label>
        <Input
          type="password"
          name="password"
          id="passord"
          placeholder="Passord"
          value={values.passord}
          onChange={(e) => updateValues({ ...values, passord: e.target.value })}
          onKeyPress={(k) => keyPressed(k)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="serviceSelect">*Service</Label>
        <Input
          type="select"
          name="serviceSelect"
          id="serviceSelect"
          onChange={(e) =>
            updateValues({ ...values, serviceId: e.target.value })
          }
          value={values.serviceId}
        >
          {selectInput.map((i) => (
            <option value={i.value}>{i.name}</option>
          ))}
        </Input>
      </FormGroup>
      <Button onClick={() => login(values)}>Log inn</Button>
    </>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  // onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
};

export default LoginForm;
